.App {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
 /* box-sizing: border-box; */
}

  @media screen and (max-width: 360px) {
    body {
      overflow-y: scroll;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (min-width: 767px) {
  .container{
    padding-left: 3rem;
    padding-right: 3rem;
    margin-bottom: 5rem;
  }
}

.container {
  padding: 0px calc(1.5rem);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
